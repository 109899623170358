<template>
  <div class="list-content-page">
    <div class="ym-title">育雏信息</div>
    <!-- 条件筛选 -->
    <div class="ym-panel-general">
      <el-form
        class="fl"
        :inline="true"
        :model="form"
      >
        <el-form-item prop="productName" label="产品名称：">
          <el-input
            v-model="form.productName"
            size="medium"
            placeholder="全部"
            maxlength="50"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          class="ym-input-left"
          prop="batchNum"
          label="产品批次编号："
        >
          <el-input
            v-model="form.batchNum"
            size="medium"
            placeholder="全部"
            maxlength="50"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
         <el-button class="ym-input-left" type="primary" round size="medium" @click="submit">查询</el-button>
         <el-button plain round size="medium" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="ym-panel-table">
      <!-- 操作按钮 -->
      <div class="btns">
        <el-button
          type="primary"
          round
          icon="el-icon-plus"
          size="medium"
          @click="toAdd"
          >新增</el-button
        >
      </div>
      <!--表格-->
      <el-table
        :data="tableData"
        v-loading="loading"
        highlight-current-row
        class="ym-table"
        size="mini"
        stripe
        border
        :row-style="{height:'40px'}"
        :cell-style="{padding:'0px'}"
      >
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="files"
          label="鸡舍图片"
          min-width="100"
          align="center"
        >

        <template slot-scope="scope">
            <el-popover
              placement="top"
              width="260"
              trigger="hover">
              <img :src="scope.row.files[0].fileUrl" alt="" width="230">
              <img class="ym-jishe" slot="reference" :src="scope.row.files[0].fileUrl" alt="">
            </el-popover>
        </template>

        </el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="batchNum"
          label="批次编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sourceCompanyName"
          label="种源提供商名称"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="intakeHennery"
          label="入住鸡舍"
          min-width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="intakeQty"
          label="入住数量"
          min-width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="intakeTime"
          label="入住日期"
          min-width="120"
          align="center"
        >
        <template slot-scope="scope">
            <i v-if="scope.row.intakeTime" class="el-icon-date"></i>
            <span style="margin-left: 6px">{{ scope.row.intakeTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sourceIntroduce"
          label="种源介绍"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            
            <el-tooltip
              class="item"
              effect="light"
              :content="scope.row.sourceIntroduce"
              placement="top"
            >
            <i class="iconfont iconchakan icon-btn"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="afcBatchInId" align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <template v-if="scope.row.chainFlag===1">
                <el-button type="text" @click="toSave(scope.row)">编辑</el-button>
                <el-button type="text" @click="deleteTable(scope.row)">删除</el-button>
            </template>
            <el-button type="text" @click="toGet(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        popper-class="page-select-dropdown"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 32
      },
      form: {
        productName: '',
        batchNum: ''
      }
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  methods: {
    toAdd() {
      this.$router.push({ path: '/listBroods/saveBrood' }).catch(err => err)
    },
    indexMethod(index) {
      if (index + 1 < 10) {
        return '0' + (index + 1)
      }
      return index + 1
    },
    toGet(row) {
      this.$router.push({ path: '/listBroods/getBrood', query: { id: row.afcBatchInId }})
    },
    toSave(row) {
      console.log(row)
      this.$router.push({ path: '/listBroods/saveBrood', query: { id: row.afcBatchInId }})
    },
    pageSizeChange(val) {
      this.page.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.page.currentPage = val
      this.getList()
    },
    // 条件搜索
    submit() {
      this.getList()
    },
    // 条件重置
    reset() {
      this.form.batchNum = ''
      this.form.productName = ''
      this.getList()
    },
    // 删除列表
    deleteTable(row) {
      // 确认删除操作
      this.$confirm('确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作
        this.remove(row.afcBatchInId)
      }).catch(() => {
      })
    },
    async getList() {
      const params = {
        batchNum: this.form.batchNum,
        productName: this.form.productName,
        size: this.page.pageSize,
        current: this.page.currentPage
      }
      this.loading = true
      const { data } = await this.$http.broodInfor.listBroods(params)
      if (data.code === '0') {
        const records = data.data.records
        var array = []
        for (var item of records) {
          var obj = {}
          obj.afcBatchInId = item.afcBatchInId
          obj.intakeQty = item.intakeQty
          obj.batchNum = item.batchNum
          obj.productName = item.productName
          obj.sourceCompanyName = item.sourceCompanyName
          obj.intakeHennery = item.intakeHennery
          obj.files = item.files
          obj.intakeTime = item.intakeTime.substr(0, 10)
          obj.sourceIntroduce = item.sourceIntroduce
          obj.chainFlag = item.chainFlag
          array.push(obj)
        }
        this.tableData = array
        console.log('列表：', this.tableData)
        this.page.total = parseInt(data.data.total)
      } else {
        this.$message.error(data.msg || '获取列表失败')
      }
      this.loading = false
    },
    // 删除
    async remove(afcBatchInId) {
      const params = {
        afcBatchInId: afcBatchInId
      }
      this.loading = true
      const { data } = await this.$http.broodInfor.removeBrood(params)
      if (data.code !== '0') return this.$message.error(data.msg || '删除失败')
      else {
        this.$message.success(data.msg || '删除成功')
        if (this.tableData.length === 1) {
          this.page.currentPage -= this.page.currentPage
          this.getList()
        } else {
          this.getList()
        }
        this.loading = false
      }
    }

  }
}
</script>

<style lang="scss" scrope>
.list-content-page {
  .el-form-item {
      margin-bottom: 0;
    }
  .ym-panel-table {
    .icon-btn{
      font-size: 12px;
      color: #3E6DF5;
    }
  }
  .btns {
    text-align: right;
  }
  .ym-table {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }
  // 修改表格行样式
  .el-table .cell {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 23px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

  .ym-jishe{
    width: 50px;
    height: 50px;
    margin: 6px auto;
  }
}
</style>
